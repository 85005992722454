<template>
  <label
    :contenteditable="editMode"
    @keyup.enter="exitEdit($event)"
    @focus="setSelectedElement('form-label', $event)"
    @blur="hitTheBlur"
    :class="{ required: isRequired }"
    v-html="label"
    :id="labelId"
  ></label>
</template>

<script>
import setSelected from '@forms/mixins/setSelected';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { uuid } from 'vue-uuid';

export default {
  mixins: [setSelected],
  data() {
    return {
      labelId: uuid.v4()
    };
  },
  props: {
    editMode: {
      type: Boolean
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    label: {
      type: String
    },
    questionId: {
      type: String
    },
    groupId: {
      type: String
    }
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(`[id="${this.labelId}"]`, ['boldStyle', 'italicStyle', 'anchorLink']);
    }
  },
  methods: {
    hitTheBlur(event) {
      if (this.editMode) {
        let innerHTML = event.target.innerHTML;
        if (innerHTML.trim() === '<p></p>' || innerHTML.trim() === '<p><br></p>' || innerHTML.trim() === '<br>') {
          innerHTML = '';
        }
        const value = {
          target: {
            innerHTML: innerHTML
          },
          relatedTarget: {
            innerText: 'link'
          }
        };
        this.editFormItemLabel('title', value);
      }
    },
    editFormItemLabel(type, event) {
      if (!event || !event.relatedTarget || event.relatedTarget.innerText !== 'link') {
        this.setSelectedElement(null, event); // reset
      }
      const value = event.target.innerHTML
        .replace(/(.*?)<br>/g, '$1')
        .replace(/(.*?)<\/br>/g, '$1')
        .replace(/<br></g, '<')
        .replace(/<\/br></g, '<')
        .replace(/&nbsp;/gi, '')
        .replace(/<p>/g, '')
        .replace(/<\/p>/g, '')
        .replace(/<p><\/p>/g, '')
        .replace(/<b><\/b>/g, '')
        .replace(/<i><\/i>/g, '');

      this.$store.commit('formBuilder/editFormQuestionProp', {
        type,
        value
      });
    },
    exitEdit(event) {
      event.target.blur();
    }
  }
};
</script>

<style lang="scss" scoped>
label {
  width: 100%;
  word-break: normal;

  &.required::after {
    content: ' *';
    font-size: var(--subtitle-size);
    vertical-align: middle;
  }
}
</style>
