<template>
  <div class="row my-2">
    <label v-if="label" :class="{ 'col-6': !twolines, 'col-12': twolines, checked: value }" :for="id">{{
      label
    }}</label>
    <div :class="{ 'col-6': !twolines && label, 'col-12': twolines || !label }">
      <el-input
        :value="value"
        :type="type"
        :placeholder="placeholder"
        @input="changeValue"
        @blur="updateValue"
        class="input-element"
      ></el-input>
    </div>
  </div>
</template>

<script>
import { Input } from 'element-ui';

export default {
  props: {
    twolines: {
      type: Boolean,
      default: false
    },
    id: {
      type: String
    },
    label: {
      type: String
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    }
  },
  components: {
    elInput: Input
  },
  methods: {
    changeValue(value) {
      this.$emit('changeValue', value);
    },
    updateValue(value) {
      this.$emit('updateValue', value.srcElement.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
label {
  display: flex;
  align-items: center;
  color: $color-secondary;
  font-size: var(--text-size-small);
}

.input-element {
  width: 100%;
}
</style>

<style lang="scss">
@import '@shared/styles/_colors.scss';
.el-switch__label,
.el-switch__label span {
  color: $color-primary !important;
  font-size: var(--text-size-small) !important;
  font-weight: normal !important;
}
.el-switch.is-checked .el-switch__core,
.el-switch__core {
  background-color: rgba(2, 28, 44, 0.03) !important;
  border-color: rgba(2, 28, 44, 0.03) !important;
}

.el-switch__label.is-active span {
  color: $color-secondary !important;
}

.el-switch.is-checked .el-switch__core:after {
  background-color: rgba(2, 28, 44, 0.3) !important;
}

.el-switch .el-switch__core:after {
  background-color: rgb(255, 255, 255) !important;
}
</style>
