<template>
  <div class="group-container" :id="groupId" :class="{ selected: selected, 'no-children': childs === 0 }">
    <div class="indicators" @click="setSelectedItem">
      <div class="required-indication required" v-if="isRequiredGroup || isDependencyQuestion">
        {{ 'forms.requiredGroup' | translate }}
      </div>
      <div class="repeating-area ml-3" v-if="repeatable">
        <i class="material-icons">repeat</i>
        {{ 'forms.repeatingArea' | translate }}
      </div>
    </div>

    <div class="more-options-container" v-if="editMode">
      <form-action-drd
        :title="$t('forms.rearrangeQuestions')"
        elements="Questions"
        class="group-actions-drd"
      ></form-action-drd>
    </div>
    <div class="group">
      <div class="group-content-wrapper">
        <div class="selectItem" v-if="editMode && allElementsDisabled" @click="setSelectedItem">
          <!-- <i class="material-icons more_vert">create</i>select group -->
        </div>
        <i v-if="childs > 0" class="material-icons delete-child" @click="removeChild(0)">delete</i>
        <slot></slot>
      </div>
      <div v-for="child in childs" :key="child" class="group-content-wrapper">
        <i class="material-icons delete-child" @click="removeChild(child)">delete</i>
        <slot></slot>
      </div>
      <div class="repeatable-button" v-if="repeatable && !editMode" @click="addChild()">
        <button class="material-icons">add</button>
        {{ buttonText }}
      </div>
      <form-add-question-marker
        @click="setSelectedItem"
        :fromGroup="groupId"
        v-if="editMode"
      ></form-add-question-marker>
    </div>
  </div>
</template>

<script>
import FormActionDrd from '@forms/components/FormActionDrd.vue';
import FormAddQuestionMarker from '@forms/components/form-items/group/FormAddQuestionMarker.vue';
import { mapGetters } from 'vuex';
const Ajv = require('ajv');
export default {
  components: {
    FormActionDrd,
    FormAddQuestionMarker
  },
  props: {
    repeatable: {
      type: Boolean
    },
    editMode: {
      type: Boolean
    },
    buttonText: {
      type: String
    },
    groupId: {
      type: String
    },
    sectionId: {
      type: String
    },
    groupSchema: {
      type: Object
    },
    schema: {
      type: Object
    },
    initialValue: {},
    required: {
      type: Array
    }
  },
  data() {
    return {
      childs: 0,
      ajv: null
    };
  },
  methods: {
    setSelectedItem(event) {
      event.preventDefault();
      if (!this.editMode) {
        return;
      }
      const sectionId = event.target
        .closest('.section-container')
        .id.split('/')
        .slice(-2)
        .join('/');
      const groupId = event.target.closest('.group-container') ? event.target.closest('.group-container').id : null;
      this.$store.commit('formBuilder/setSelectedItem', {
        sectionId,
        groupId,
        questionId: null
      });
    },
    removeChild(child) {
      if (child !== this.childs) {
        for (let index = child; index < this.childs; index++) {
          Object.keys(this.groupSchema.properties).forEach((key) => {
            this.$el.querySelector(
              `.group-content-wrapper:nth-child(${index + 1}) input[id='${key}']`
            ).value = this.$el.querySelector(`.group-content-wrapper:nth-child(${index + 2}) input[id='${key}']`).value;
            this.$el
              .querySelector(`.group-content-wrapper:nth-child(${index + 1}) input[id='${key}']`)
              .dispatchEvent(new Event('change'));
          });
        }
      }
      this.childs--;
    },
    addChild() {
      this.ajv = new Ajv({ allErrors: true });
      let model = {};
      const len = this.$el.querySelectorAll('.group-content-wrapper').length - 1;
      Object.keys(this.groupSchema.properties).forEach((e) => {
        const selector = this.$el.querySelectorAll(`input[id='${e}']`)[len];
        if (selector && selector.value) {
          switch (this.groupSchema.properties[e].type) {
            case 'number':
            case 'integer':
              model[e] = Number(selector.value);
              break;
            case 'boolean':
              model[e] = Boolean(selector.value);
              break;
            case 'object':
            case 'array':
              model[e] = JSON.parse(selector.value);
              break;
            default:
              model[e] = selector.value;
              break;
          }
        }
      });
      let validSchema = this.groupSchema;
      validSchema.schema = 'http://json-schema.org/draft-07/schema#';
      validSchema.type = 'object';
      validSchema.definitions = this.schema.definitions;
      validSchema.required = this.required;
      var validate = this.ajv.compile(validSchema);
      var valid = validate(model);
      this.$el.querySelectorAll('.alert-danger').forEach((e) => (e.style.display = 'none'));
      if (valid) {
        this.childs++;
      } else {
        console.error('Form Validation Error: ', validate.errors);
        validate.errors.forEach((error) => {
          let warning;
          if (error.keyword === 'required') {
            warning = this.$el.querySelector(
              `.group-content-wrapper:nth-child(${len + 1}) [class*="warning-required-${error.params.missingProperty}"]`
            );
          } else if (error.keyword === 'pattern' || error.keyword === 'type') {
            let key = error.dataPath
              .replace(/'/g, '')
              .replace('[', '')
              .replace(']', '')
              .replace(/"/g, '');
            warning = this.$el.querySelector(
              `.group-content-wrapper:nth-child(${len + 1}) [class*="warning-pattern-${key}"]`
            );
          }
          if (warning) {
            warning.style.display = 'block';
          }
        });
      }
    }
  },
  mounted() {
    if (this.initialValue && this.initialValue.length > 0 && this.repeatable) {
      this.childs = this.initialValue.length - 1;
      setTimeout(() => {
        this.initialValue.forEach((elem, index) => {
          Object.keys(elem).forEach((key) => {
            let element = this.$el.querySelector(`.group-content-wrapper:nth-child(${index + 1}) input[id='${key}']`);
            if (element) {
              element.value = typeof elem[key] === 'string' ? elem[key] : JSON.stringify(elem[key]);
              this.$el
                .querySelector(`.group-content-wrapper:nth-child(${index + 1}) input[id='${key}']`)
                .dispatchEvent(new Event('change'));
            }
          });
        });
      }, 10);
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedGroup', 'selectedItem']),
    selected() {
      if (!this.selectedGroup.key) {
        return false;
      }
      return this.selectedGroup.key === this.groupId && !this.selectedItem.questionId;
    },
    isRequiredGroup() {
      return this.groupSchema.minItems && this.groupSchema.minItems >= 1 ? true : false;
    },
    isDependencyQuestion() {
      if (this.groupSchema) {
        const key = this.groupSchema.index.split('.')[1];
        return !!this.$store.getters['formBuilder/dependencyQuestionDataById'](key);
      }
      return false;
    },
    allElementsDisabled() {
      if (!this.groupSchema) {
        return;
      }
      return !this.groupSchema.data.showTitle && !this.groupSchema.data.showDescription;
      // return !this.selectedGroup.schema.data.showTitle && !this.selectedGroup.schema.data.showDescription;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';

// Dont show group border for onboardee when empty group
.learning-page-content {
  .group-container {
    &.no-children {
      border: none;
    }
  }
}

.group-container {
  label {
    width: 95%;
    margin-top: 30px;
    font-weight: bold;
  }
  label[contenteditable='true'] {
    margin-left: 20px;
  }
  p[contenteditable='true'] {
    margin-left: 20px;
  }

  &.selected {
    border: 1px solid #d9dde0 !important;
    border-radius: 4px;
    background-color: rgba(0, 170, 255, 0.06);
  }

  &:hover {
    /deep/.group-actions-drd {
      i {
        display: block;
        right: 10px;
      }
    }
  }
  border: 1px solid rgba(2, 28, 44, 0.15);
  border-radius: 4px;
  min-height: 100px;
  position: relative;
  padding: 0 24px;
  margin-right: 6px;

  .more-options-container {
    height: 0;
    padding: 8px 0 0 8px;
  }

  .delete-child {
    position: relative;
    height: 0;
    width: 0;
    left: 95%;
    top: 30px;
    color: #b1b1b1;
    cursor: pointer;
  }
  .repeatable-button {
    display: flex;
    cursor: pointer;
    width: calc(100% - 16px);
    height: 48px;
    margin: 8px;
    border: 1px solid rgba(2, 28, 44, 0.3);
    background-color: #ffffff;
    color: #021c2c;
    font-family: 'Talmundo';
    font-size: var(--text-size-small);
    line-height: 16px;
    align-items: center;
    justify-content: center;
    button {
      border: none;
      background: none;
    }
  }
}

.indicators {
  position: absolute;
  top: 8px;
  right: 8px;
  height: 16px;
  line-height: 16px;
  color: $color-secondary;
}

.required-indication::before {
  content: ' *';
  font-size: var(--subtitle-size);
  vertical-align: middle;
}

.required-indication,
.repeating-area {
  display: inline-block;
}

.repeating-area {
  .material-icons {
    vertical-align: middle;
    font-size: var(--subtitle-size);
    margin-right: 4px;
  }
}

.selectItem {
  margin-left: 20px;
  min-height: 24px;
}
</style>
