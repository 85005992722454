import { mapGetters } from 'vuex';
import find from 'lodash/find';
import isObject from 'lodash/isObject';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';

import googleFonts from '../store/googleFonts';

export default {
  computed: {
    ...mapGetters('settings', ['style', 'subdomain'])
  },
  data() {
    return {
      availableFonts: [
        { fileName: 'Arial', key: 'arial' },
        { fileName: 'Comic Sans MS', key: 'comic_sans_ms' },
        { fileName: 'Arial Black', key: 'arial_black' },
        { fileName: 'Calibri', key: 'calibri' },
        { fileName: 'Cambria', key: 'cambria' },
        { fileName: 'Helvetica', key: 'Helvetica' },
        { fileName: 'Georgia', key: 'georgia' },
        { fileName: 'Tahoma', key: 'tahoma' },
        { fileName: 'Times New Roman', key: 'times_new_roman' },
        { fileName: 'Verdana', key: 'verdana' }
      ],
      googleFonts: googleFonts.slice(0, 100).map((font) => {
        return { fileName: font };
      })
    };
  },
  watch: {
    style(newValue) {
      const el = document.querySelector('#theme');
      if (el) {
        el.parentNode.removeChild(el);
      }
      let style = document.createElement('style');
      style.type = 'text/css';
      style.id = 'theme';

      let googleFonts = [];
      let customFonts = [];

      if (typeof this.style.font === 'string') {
        googleFonts.push(this.style.font);
      } else if (typeof this.style.font === 'object') {
        customFonts.push(this.style.font);
      }
      if (typeof this.style.buttonFont === 'string') {
        googleFonts.push(this.style.buttonFont);
      } else if (typeof this.style.buttonFont === 'object') {
        customFonts.push(this.style.buttonFont);
      }
      if (typeof this.style.titleFont === 'string') {
        googleFonts.push(this.style.titleFont);
      } else if (typeof this.style.titleFont === 'object') {
        customFonts.push(this.style.titleFont);
      }

      document.querySelector('#theme-color').setAttribute('content', this.style.colorPrimary);

      googleFonts = uniq(googleFonts);
      customFonts = uniqBy(customFonts, 'key');

      googleFonts.forEach((font) => {
        font = font.replace(/\s/g, '');
        if (find(this.availableFonts, (f) => f.fileName.toLowerCase() === font.toLowerCase())) return;
        const el = document.querySelector('#' + font.replace(/"/g, ''));
        if (el) {
          el.parentNode.removeChild(el);
        }
        let link = document.createElement('link');
        link.rel = 'stylesheet';
        link.id = font;
        const fileName = font
          .split(/\.?(?=[A-Z])/)
          .join('-')
          .toLowerCase();
        link.href = `https://static.talentech.io/googlefonts/css2?family=${font}&display=swap`;
        document.head.appendChild(link);
      });

      const font = typeof this.style.font === 'string' ? this.style.font : this.style.font.fileName.split('.')[0];
      const titleFont =
        typeof this.style.titleFont === 'string' ? this.style.titleFont : this.style.titleFont.fileName.split('.')[0];

      const checkUploadFile = (obj) => {
        if (this.$route.path.indexOf('/theme-editor') >= 0) {
          return;
        }
        if (obj.url && obj.url.indexOf('.blob.core') >= 0) {
          var url = window.location.protocol + '//' + window.location.host + '/' + window.location.pathname;
          if (url.indexOf('.local:5000')) {
            let splitUrl = process.env.VUE_APP_API_URL.split('.');
            splitUrl.shift();
            url = 'https://' + this.subdomain + '.' + splitUrl.join('.');
          }
          obj.url = url += '/uploadedfiles/companyfile/' + obj.key;
        }
      };

      if (isObject(this.style.loginBackground)) {
        checkUploadFile(this.style.loginBackground);
      }

      if (isObject(this.style.dashboardBackground)) {
        checkUploadFile(this.style.loginBackground);
      }

      if (isObject(this.style.background)) {
        checkUploadFile(this.style.background);
      }

      const isAdminPath = [
        'form-editor/editor',
        'form-editor/library',
        'learning-pages/library',
        'learning-pages/editor',
        'learning-pages/tree-view'
      ].some((element) => this.$route.path.includes(element));
      style.appendChild(
        document.createTextNode(
          `
          ${
            customFonts.length
              ? customFonts
                  .map((f) => {
                    return `@font-face {
              font-family: '${f.fileName.split('.')[0]}';
              src: url(${f.url});
              font-style: normal;
              font-weight: normal;
            }`;
                  })
                  .join('\n')
              : ''
          }


        body, #editor .content-block-label, #preview .content-block-label {
          font-family: "open sans","Helvetica Neue",Helvetica,Arial,sans-serif;
        }

        #app, #theme-editor .editor-col, #editor .content-block, #preview .content-block, .tm-dashboard-lp, .tm-dashboard-lp a, #sub-level-navigation, #sub-level-navigation a {
          color: ${this.style.colorText} !important;
          font-family: ${font};
        }

        #app h1, #app h2, #app h3, #app h4, #app h5, #app .module-card .card-header, #app .title, #theme-editor .editor-col .card-header, #theme-editor h5 {
          font-family: ${titleFont};
          color: ${this.style.colorTitle ? this.style.colorTitle : this.style.colorPrimary};
        }

        .content-block h1, .content-block h2, .content-block h3, .content-block h4, .content-block h5, .content-block .module-card .card-header, .content-block .title {
          font-family: ${titleFont};
          color: ${this.style.learningPageTitleColor ? this.style.learningPageTitleColor : this.style.colorTitle};
        }
        .form-group .btn.btn-primary {
          font-family: ${font};
          background-color:  ${this.style.colorPrimaryButton ? this.style.colorPrimaryButton : '#aeaeae'} ${
            isAdminPath ? '' : '!important'
          };
        }

        .section-content-wrapper h1, .form-content-block h1 {
          font-family: ${titleFont};
          color: ${this.style.colorPrimary ? this.style.colorPrimary : this.style.colorTitle};
        }

        .section-content-wrapper p, .question label, input.form-control, .group-content-wrapper p, .group-content-wrapper label, .gdpr-intro  {
          font-family: ${font};
          color: ${this.style.colorText ? this.style.colorText : this.style.colorPrimary};
        }

        #page-content-wrapper {
          ${
            isObject(this.style.background)
              ? `background-image: url("${this.style.background.url}") !important;
              `
              : `${
                  this.style.background && this.style.background.indexOf('url') === 0
                    ? 'background-image'
                    : 'background'
                }: ${this.style.background} !important;`
          }
          background-size: cover !important;
          background-position: center center;
          background-repeat: no-repeat;
          background-attachment: fixed;
        }

        #dashboard {
          ${
            isObject(this.style.dashboardBackground)
              ? `background-image: url("${this.style.dashboardBackground.url}") !important;
              `
              : `${
                  this.style.dashboardBackground && this.style.dashboardBackground.indexOf('url') === 0
                    ? 'background-image'
                    : 'background'
                }: ${this.style.dashboardBackground} !important;`
          }
          background-size: cover !important;
          background-position: center center;
          background-repeat: no-repeat;
          background-attachment: fixed;
        }

        .module-card card-header {
          font-family: ${this.style.font};
        }

        .tm-dashboard-lp .card:hover, .tm-dashboard-lp .swiper-container .swiper-slide:hover, #sub-level-navigation .card:hover, #same-level-navigation .card:hover {
          border: 2px solid ${this.style.learningPageGridOutlineColor || this.style.colorPrimary} !important;
        }
        .tm-dashboard-lp a, .tm-dashboard-pulse-check a, .swiper-slide a {
          display: block;
        }
        .card-header-action a {
          color: ${this.style.colorSecondary};
        }
        .el-carousel__indicator.is-active .el-carousel__button {
          background-color: ${this.style.colorPrimary};
        }
        .tm-dashboard-lp .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background-color: ${this.style.learningPageIndicatorColor};
        }
        .tm-dashboard-lp .swiper-button-prev, .tm-dashboard-lp .swiper-button-next {
          color: ${this.style.learningPageIndicatorColor};
        }
        .did-you-know .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background-color: ${this.style.didYouKnowIndicatorColor};
        }
        .swiper-pagination-bullet.swiper-pagination-bullet-active {
          background-color: ${this.style.learningPageIndicatorColor};
        }
        .el-button--primary,.el-button--primary:active,.el-button--primary:focus{
          background-color: ${this.style.colorPrimaryButton};
          border-color: ${this.style.colorPrimaryButton};
          color: ${this.style.colorPrimaryButtonText};
        }
        .Cookie__buttons a.Cookie__button, .Cookie__buttons button.Cookie__button {
          background-color: ${this.style.colorPrimaryButton} !important;
          border-color: ${this.style.colorPrimaryButton} !important;
          color: ${this.style.colorPrimaryButtonText} !important;
        }
        .Cookie__buttons a.Cookie__button:focus, .Cookie__buttons button.Cookie__button:focus {
          outline: none !important;
          -webkit-box-shadow: 0 0 0 2px ${this.style.colorPrimary} !important;
          box-shadow: 0 0 0 2px ${this.style.colorPrimary} !important;
        }
        .navbar-header i, .navbar i {
          color: ${this.style.headerIconColor};
        }

        .icon-badge:after {
          background: ${this.style.notificationBadgeColor} !important;
          color: ${this.style.notificationBadgeTextColor} !important;
        }

        .el-button--primary:hover {
          background-color: ${this.style.colorPrimaryButton};
          border-color: ${this.style.colorPrimaryButton};
          color: ${this.style.colorPrimaryButtonText};
          opacity: .8;
        }
        .el-button--default:not(.el-button--primary),.el-button--default:not(.el-button--primary):active,.el-button--default:not(.el-button--primary):focus {
          background-color: ${this.style.colorDefaultButton};
          border-color: ${this.style.colorPrimaryButton};
          color: ${this.style.colorDefaultButtonText};
        }
        .el-button--default:not(.el-button--primary):hover {
          background-color: ${this.style.colorDefaultButton};
          border-color: ${this.style.colorPrimaryButton};
          color: ${this.style.colorDefaultButtonText};
          opacity: .8;
        }
        .el-progress-bar__inner {
          background-color: ${this.style.progressColor};
        }

        .el-progress__text {
          color: ${this.style.progressTextColor};
        }

        .el-step__head.is-finish, .el-step__title.is-finish, .el-step__description.is-finish {
          color: ${this.style.colorPrimary};
          border-color: ${this.style.colorPrimary};
        }
        .el-loading-spinner .path {
          stroke: ${this.style.colorPrimary};
        }
        .el-tabs__item.is-active, .el-tabs__item:hover {
          color: ${this.style.colorPrimary};
        }
        .el-tabs__active-bar {
          background-color: ${this.style.colorPrimary};
        }
        textarea:focus, textarea.form-control:focus, input.form-control:focus, input[type=text]:focus, input[type=password]:focus, input[type=email]:focus, input[type=number]:focus, [type=text].form-control:focus, [type=password].form-control:focus, [type=email].form-control:focus, [type=tel].form-control:focus, [contenteditable].form-control:focus, input.small-input:focus {
          -webkit-box-shadow: inset 0 -2px 0 ${this.style.colorPrimary} !important;
          box-shadow: inset 0 -2px 0 ${this.style.colorPrimary} !important;
        }
        .el-message-box__headerbtn:focus .el-message-box__close, .el-message-box__headerbtn:hover .el-message-box__close {
          color: ${this.style.colorPrimary} !important;
        }
        .el-collapse-item__header {
          background-color: ${this.style.colorPrimary};
          color: white;
        }
        .vue-tel-input:focus, .vti__dropdown:focus, .vue-tel-input:focus-within {
          outline: none !important;
          box-shadow: 0 0 1px 1px ${this.style.colorPrimary} !important;
          border-color: ${this.style.colorPrimary} !important;
        }
        input[type="file"]:focus + .input-file-container {
          border: 1px solid ${this.style.colorPrimary} ;
          border-radius: 4px;
          border-style: dashed;
          }
        input[type="checkbox"]:checked:after, .checkbox input[type="checkbox"]:checked:after, .checkbox-inline input[type="checkbox"]:checked:after {
          background-color: ${this.style.colorPrimary};
          border-color: ${this.style.colorPrimary};
        }
        input[type="checkbox"]:focus:after, .checkbox input[type="checkbox"]:focus:after, .checkbox-inline input[type="checkbox"]:focus:after {
          border-color: ${this.style.colorPrimary} !important;
        }
        input[type="radio"]:focus + label:before, .breadcrums-container select:focus {
          outline: none !important;
          border: 1px solid #4c9aff;
          -webkit-box-shadow: 0 0 0 2px ${this.style.colorPrimary} !important;
          box-shadow: 0 0 0 2px ${this.style.colorPrimary} !important;
        }
        #page-content-wrapper button.material-icons:focus, .card-desc:focus , #page-content-wrapper a:focus, #page-content-wrapper .btn-primary:focus, #page-content-wrapper .swiper-pagination-bullet:focus, .sidebar-nav button:focus, .el-message-box__headerbtn:focus, .el-button:focus, .rating:focus, .sidebar-nav a:focus, .timeline-content:focus, .formFinished button:focus, .scroll-top:focus {
          outline: none !important;
          -webkit-box-shadow: 0 0 0 2px ${this.style.colorPrimary} !important;
          box-shadow: 0 0 0 2px ${this.style.colorPrimary} !important;
        }

        .el-checkbox__input.is-checked .el-checkbox__inner {
          background-color: ${this.style.colorPrimaryButton} !important;
          border-color: ${this.style.colorPrimaryButton} !important;
        }

        .el-pagination.is-background .el-pager li:not(.disabled).active {
          background-color: ${this.style.colorPrimaryButton} ${isAdminPath ? '' : '!important'};
          color: ${this.style.colorPrimaryButtonText} ${isAdminPath ? '' : '!important'};
        }

        .el-pagination.is-background .el-pager li:not(.disabled):hover {
          color: ${this.style.colorPrimaryButton} ${isAdminPath ? '' : '!important'};
        }

        .badge {
          background-color: ${this.style.colorPrimaryButton} ${isAdminPath ? '' : '!important'};
          color: ${this.style.colorPrimaryButtonText} ${isAdminPath ? '' : '!important'};
        }

        .btn.btn-primary {
          background-color: ${this.style.colorPrimaryButton} ${isAdminPath ? '' : '!important'};
          color: ${this.style.colorPrimaryButtonText} ${isAdminPath ? '' : '!important'};
        }

        html {
          font-size: ${this.style.fontSize}px !important;
        }

        ${this.style.advanced && this.style.advanced.length > 3 ? this.style.advanced : ''}

      `
        )
      );
      this.styleNode = style.childNodes[0];
      document.head.appendChild(style);
    }
  }
};
