/* eslint-disable no-console */
import { upsertSubscription } from '@app/http/notification';

export const registerServiceWorkerNotifications = async () => {
  if ('serviceWorker' in navigator) {
    try {
      const registration = await navigator.serviceWorker.register('/sw.js', {
        scope: '/'
      });

      if (Notification.permission === 'granted') {
        document.querySelector('#allowNotifications').style.display = 'none';
        document.querySelector('#notificationsEnabled').style.display = 'block';
        getSubscription(registration);
      } else if (Notification.permission === 'blocked' || Notification.permission === 'denied') {
        document.querySelector('#allowNotifications').style.display = 'none';
        document.querySelector('#notificationsBlocked').style.display = 'block';
      } else {
        document
          .querySelector('#allowNotifications')
          .addEventListener('click', () => requestNotificationAccess(registration));
      }
    } catch (error) {
      console.error(`Registration failed with ${error}`);
    }
  }
};

function requestNotificationAccess(registration) {
  Notification.requestPermission(function(status) {
    if (status == 'granted') {
      document.querySelector('#allowNotifications').style.display = 'none';
      document.querySelector('#notificationsEnabled').style.display = 'block';
      getSubscription(registration);
    } else {
      document.querySelector('#notificationsBlocked').style.display = 'block';
    }
  });
}

function getSubscription(registration) {
  registration.pushManager.getSubscription().then(function(sub) {
    if (sub === null) {
      registration.pushManager
        .subscribe({
          userVisibleOnly: true,
          applicationServerKey: window.localStorage.getItem('publicKeyNotifications')
        })
        .then(function(sub) {
          logSubscription(sub);
          upsertSubscription(sub);
        })
        .catch(function(e) {
          console.error('Unable to subscribe to push', e);
        });
    } else {
      logSubscription(sub);
      // upsertSubscription(sub);
    }
  });
}

const logSubscription = (sub) => {
  console.log(sub);
};
// registerServiceWorker();
