<template>
  <div class="question-container" :class="{ selected: selected }">
    <div class="more-options-container" v-if="editMode">
      <form-action-drd
        :title="$t('forms.rearrangeQuestions')"
        elements="Questions"
        class="question-actions-drd"
      ></form-action-drd>
    </div>
    <div class="question" @click="setSelectedItem">
      <div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import FormActionDrd from '@forms/components/FormActionDrd.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    FormActionDrd
  },
  props: {
    editMode: {
      type: Boolean
    },
    questionId: {
      type: String
    },
    sectionId: {
      type: String
    },
    groupId: {
      type: String
    },
    onTop: {
      type: Boolean,
      required: false
    }
  },
  methods: {
    setSelectedItem(event) {
      if (!this.editMode) {
        return;
      }

      event.preventDefault();
      const questionId = event.target.closest('.question-container').id;
      const sectionId = event.target
        .closest('.section-container')
        .id.split('/')
        .slice(-2)
        .join('/');
      let groupId = event.target.closest('.group-container') ? event.target.closest('.group-container').id : null;
      this.$store.commit('formBuilder/setSelectedItem', {
        sectionId,
        groupId,
        questionId
      });
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedItem']),
    selected() {
      if (!this.selectedItem) {
        return false;
      }
      return this.selectedItem.questionId === this.questionId;
    }
  }
};
</script>

<style lang="scss">
.input-choice-container {
  .choice-actions-drd {
    height: 0;
    width: 0;
  }

  &:hover {
    .choice-actions-drd {
      i {
        display: block;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.required label::after {
  content: ' *';
  font-size: var(--subtitle-size);
  vertical-align: middle;
}

.question-container {
  &:hover {
    /deep/.question-actions-drd {
      i {
        display: block;
      }
    }
  }

  display: flex;
  flex-direction: row;
  margin: 16px 0 16px 0;
  padding: 16px 6px 16px 0;
  .question {
    width: 97%;

    textarea {
      min-height: 70px;
      font-family: 'Raleway';
    }
  }
  &.selected {
    border: 1px solid #d9dde0 !important;
    border-radius: 4px;
    background-color: rgba(0, 170, 255, 0.06);
  }
}
</style>
