<template>
  <div class="row my-2">
    <label class="col-8" :for="id" :class="{ checked: value }">{{ label }}</label>
    <div class="col-4">
      <el-input-number
        @change="$emit('change', $event)"
        class="float-right"
        :id="id"
        :inactive-text="value ? $t('general.yes') : $t('general.no')"
        :value="value"
        :min="min"
        :max="max"
      ></el-input-number>
    </div>
  </div>
</template>

<script>
import { InputNumber } from 'element-ui';

export default {
  props: {
    id: {
      type: String
    },
    label: {
      type: String
    },
    value: {
      type: Number
    },
    min: {
      type: Number,
      default: 1
    },
    max: {
      type: Number
    }
  },
  components: {
    elInputNumber: InputNumber
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
label {
  display: flex;
  align-items: center;
  color: rgba(2, 28, 44, 0.5);
  font-size: var(--text-size-small);
}

/deep/ .el-input-number__decrease,
/deep/ .el-input-number__increase {
  background-color: #f7f8f8;
  border: unset;
  font-size: var(--subtitle-size);
}

/deep/ .el-input__inner {
  background-color: #f7f8f8 !important;
}
/deep/ .el-input-number {
  width: 150px;
  i {
    font-weight: 1000;
    color: #afb7be;
  }
}
</style>

<style lang="scss">
@import '@shared/styles/_colors.scss';
.el-switch__label,
.el-switch__label span {
  color: $color-primary !important;
  font-size: var(--text-size-small) !important;
  font-weight: normal !important;
}
.el-switch.is-checked .el-switch__core,
.el-switch__core {
  background-color: rgba(2, 28, 44, 0.03) !important;
  border-color: rgba(2, 28, 44, 0.03) !important;
}

.el-switch__label.is-active span {
  color: $color-secondary !important;
}

.el-switch.is-checked .el-switch__core:after {
  background-color: rgba(2, 28, 44, 0.3) !important;
}

.el-switch .el-switch__core:after {
  background-color: rgb(255, 255, 255) !important;
}
</style>
