<template>
  <div class="container">
    <span
      class="form-rating-label"
      v-if="showLabels"
      :contenteditable="editMode"
      @blur="editFormRatingLabel($event, 'minLabel')"
      >{{ minLabel }}</span
    >
    <div class="rating-container" @mouseleave="checkHover(0)">
      <div v-if="variant === 0">
        <button
          class="rating rating-number"
          v-for="rating in max"
          :key="rating"
          type="button"
          :class="{ selected: ratingValue === rating }"
          @click="select(rating)"
        >
          {{ rating }}
        </button>
      </div>
      <div v-if="variant === 1">
        <button
          class="rating rating-star"
          v-for="rating in max"
          :key="rating"
          type="button"
          :class="{ selected: ratingValue >= rating }"
          @mouseenter="checkHover(rating)"
          @click="select(rating)"
        ></button>
      </div>
      <div v-if="variant === 2">
        <button
          class="rating rating-smiley"
          v-for="rating in max"
          :key="rating"
          type="button"
          :class="{ selected: ratingValue === rating }"
          @click="select(rating)"
        ></button>
      </div>
    </div>
    <span
      class="form-rating-label"
      v-if="showLabels"
      :contenteditable="editMode"
      @blur="editFormRatingLabel($event, 'maxLabel')"
      >{{ maxLabel }}</span
    >
  </div>
</template>
<script>
export default {
  props: {
    model: {
      type: String
    },
    minLabel: {
      type: String
    },
    maxLabel: {
      type: String
    },
    editMode: {
      type: Boolean
    },
    min: {
      type: Number
    },
    max: {
      type: Number
    },
    value: {
      type: Number
    },
    showLabels: {
      type: Boolean
    },
    variant: {
      type: Number
    }
  },
  data() {
    return {
      ratingValue: 0
    };
  },
  methods: {
    getComponent(componentName) {
      let component = null;
      let parent = this.$parent;
      while (parent && !component) {
        if (parent.$options.name === componentName) {
          component = parent;
        }
        parent = parent.$parent;
      }
      return component;
    },
    select(value) {
      if (!this.editMode) {
        this.ratingValue = value;
        this.$el.parentElement.querySelector(`[id="${this.model}"]`).value = value;
        this.$el.parentElement.querySelector(`[id="${this.model}"]`).dispatchEvent(new Event('input'));
      }
    },
    editFormRatingLabel(event, type) {
      this.$store.commit('formBuilder/editFormQuestion', {
        type: type,
        value: event.currentTarget.innerText
      });
    },
    checkHover(rating) {
      if (rating === 0) {
        this.$el.querySelectorAll('.rating-star').forEach((element, index) => {
          if (index < this.ratingValue) {
            element.classList.add('selected');
          } else {
            element.classList.remove('selected');
          }
        });

        return;
      }

      this.$el.querySelectorAll('.rating-star').forEach((element, index) => {
        if (index <= rating - 1) {
          element.classList.add('selected');
        } else {
          element.classList.remove('selected');
        }
      });
    }
  },
  created() {
    if (!this.editMode) {
      let form = this.getComponent('vue-form-json-schema');
      let value = form.model[this.model];
      this.ratingValue = value || 0;
    }
  },
  mounted() {
    if (this.$el.parentElement.querySelector(`input[id="${this.model}"]`)) {
      if (this.$el.parentElement.querySelector(`input[id="${this.model}"]`).value) {
        this.ratingValue = Number(this.$el.parentElement.querySelector(`input[id="${this.model}"]`).value);
      }
      const self = this;
      this.$el.parentElement.querySelector(`input[id="${this.model}"]`).addEventListener(
        'change',
        (newInput) => {
          self.ratingValue = Number(newInput.target.value);
        },
        false
      );
    }
  }
};
</script>

<style lang="scss" scoped>
div {
  font-family: 'Talmundo';
}
.container {
  padding: 0;
  .rating-container {
    margin: 0 6px;
    display: inline-block;

    .rating {
      cursor: pointer;
      margin: 8px;
      height: 40px;
      line-height: 40px;
      display: inline-block;
      width: 40px;
    }
    .rating-number {
      background-image: radial-gradient(circle at top right, #e9e9e9 15%, #d5d5d5 85%);
      border-radius: 20px;
      font-size: var(--text-size-small);
      font-weight: 500;
      text-align: center;
    }

    .rating-star {
      background: url('../../../images/questions/rating/star.png');
      background-size: cover;
    }

    .rating-smiley:nth-of-type(1) {
      background: url('../../../images/questions/rating/rating-happy-1.png');
    }

    .rating-smiley:nth-of-type(2) {
      background: url('../../../images/questions/rating/rating-happy-2.png');
    }

    .rating-smiley:nth-of-type(3) {
      background: url('../../../images/questions/rating/rating-happy-3.png');
    }

    .rating-smiley:nth-of-type(4) {
      background: url('../../../images/questions/rating/rating-happy-4.png');
    }

    .rating-smiley:nth-of-type(5) {
      background: url('../../../images/questions/rating/rating-happy-5.png');
    }
  }
}

.form-rating-label {
  font-weight: bold;
}

.form-rating-label[contenteditable='false']:empty {
  display: none;
}
//

.rating-number:hover,
.rating-number.selected {
  background-image: radial-gradient(circle at top right, #fdd849 15%, #fbb920 85%) !important;
}

.rating-star.selected {
  background: url('../../../images/questions/rating/star-selected.png') !important;
  background-size: cover;
}

.rating-smiley:hover:nth-of-type(1),
.rating-smiley.selected:nth-of-type(1) {
  background: url('../../../images/questions/rating/rating-happy-1-selected.png') !important;
}

.rating-smiley:hover:nth-of-type(2),
.rating-smiley.selected:nth-of-type(2) {
  background: url('../../../images/questions/rating/rating-happy-2-selected.png') !important;
}

.rating-smiley:hover:nth-of-type(3),
.rating-smiley.selected:nth-of-type(3) {
  background: url('../../../images/questions/rating/rating-happy-3-selected.png') !important;
}

.rating-smiley:hover:nth-of-type(4),
.rating-smiley.selected:nth-of-type(4) {
  background: url('../../../images/questions/rating/rating-happy-4-selected.png') !important;
}

.rating-smiley:hover:nth-of-type(5),
.rating-smiley.selected:nth-of-type(5) {
  background: url('../../../images/questions/rating/rating-happy-5-selected.png') !important;
}

.rating {
  border: none;
}
.container {
  display: flex;
  align-items: center;
}
</style>
