<template>
  <div class="section-container">
    <div class="more-options-container" v-if="editMode">
      <form-action-drd
        :title="$t('forms.rearrangeSections')"
        :elements="'Sections'"
        class="section-actions-drd"
      ></form-action-drd>
    </div>

    <div class="section" :class="textAlignment">
      <div class="section-content-wrapper" :class="{ selected: selected }">
        <div class="selectItem" v-if="allElementsDisabled && editMode" @click="setSelectedItem">
          <i class="material-icons more_vert">create</i>select section
        </div>
        <h1
          v-if="showTitle"
          :contenteditable="editMode"
          @blur="edit('title', $event)"
          @keyup.enter="exitEdit($event)"
          @focus="setSelectedElement('sectionTitle', $event)"
          v-html="title"
        ></h1>
        <p
          v-if="showDescription"
          :contenteditable="editMode"
          @keyup.enter="exitEdit($event)"
          @focus="setSelectedElement('sectionDescription', $event)"
          @blur="hitTheBlur"
          v-html="description"
          :id="descriptionId"
        ></p>
        <slot></slot>
      </div>
      <form-add-question-or-section-marker :sectionId="sectionId" v-if="editMode"></form-add-question-or-section-marker>
    </div>
  </div>
</template>

<script>
import FormAddQuestionOrSectionMarker from '@forms/components/form-items/questions/FormAddQuestionOrSectionMarker.vue';
import FormAddSectionMarker from '@forms/components/form-items/sections/FormAddSectionMarker.vue';
import FormActionDrd from '@forms/components/FormActionDrd.vue';
import mediumEditor from '@lp/components/mixins/mediumEditor.js';
import { mapGetters } from 'vuex';
import { uuid } from 'vue-uuid';
export default {
  components: {
    FormAddSectionMarker,
    FormAddQuestionOrSectionMarker,
    FormActionDrd
  },
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    },
    sectionId: {
      type: String
    },
    editMode: {
      type: Boolean
    },
    textAlignment: {
      type: String
    },
    showTitle: {
      type: Boolean
    },
    showDescription: {
      type: Boolean
    }
  },
  data() {
    return {
      index: 0,
      descriptionId: uuid.v4()
    };
  },
  mounted() {
    if (this.editMode) {
      mediumEditor(`[id="${this.descriptionId}"]`, ['boldStyle', 'italicStyle', 'anchorLink']);
    }
    if (this.$el.closest('[index]')) {
      this.data = Number(this.$el.closest('[index]').getAttribute('index'));
    }
  },
  methods: {
    hitTheBlur(event) {
      if (this.editMode) {
        let innerHTML = event.target.innerHTML;
        if (innerHTML.trim() === '<p></p>' || innerHTML.trim() === '<p><br></p>' || innerHTML.trim() === '<br>') {
          innerHTML = '';
        }
        const value = {
          target: {
            innerHTML: innerHTML
          },
          relatedTarget: {
            innerText: 'link'
          }
        };
        this.edit('description', value);
      }
    },
    edit(type, event) {
      if (type === 'description' && (!event || !event.relatedTarget || event.relatedTarget.innerText !== 'link')) {
        this.setSelectedElement(null, event); // reset
      }
      const value = event.target.innerHTML.replace(/&nbsp;/gi, '');
      if (type !== 'description') {
        event.target.innerHTML = value;
      }
      this.$store.commit('formBuilder/editFormSection', {
        type: type,
        value: value
      });
    },
    exitEdit(event) {
      event.target.blur();
    },
    setSelectedElement(type = null, event) {
      this.$store.commit('formBuilder/setSelectedElement', type);
      this.setSelectedItem(event);
    },
    setSelectedItem(event) {
      event.preventDefault();
      if (!this.editMode) {
        return;
      }
      const sectionId = event.target
        .closest('.section-container')
        .id.split('/')
        .slice(-2)
        .join('/');
      this.$store.commit('formBuilder/setSelectedItem', {
        sectionId,
        groupId: null,
        questionId: null
      });
    }
  },
  computed: {
    ...mapGetters('formBuilder', ['selectedSection', 'selectedItem']),
    selected() {
      if (!this.selectedSection.key) {
        return false;
      }
      return this.selectedSection.key === this.sectionId && !this.selectedItem.groupId && !this.selectedItem.questionId;
    },
    allElementsDisabled() {
      return !this.showTitle && !this.showDescription;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@shared/styles/_colors.scss';
.section-container {
  display: flex;
  flex-direction: row;

  .section {
    width: 100%;
    min-height: 80px;
    .selected {
      border: 1px solid #d9dde0;
      background-color: rgba(0, 170, 255, 0.06);
      border-radius: 4px;
    }

    .section-content-wrapper {
      padding: 8px 22px 8px 22px;
      min-height: 80px;
    }
  }

  &:hover {
    /deep/.section-actions-drd {
      i {
        display: block;
      }
    }
  }
}

// Don't show selection in preview
#preview-form {
  .selected {
    border: none;
    background-color: white;
    border-radius: 0px;
  }
}

h1 {
  color: $color-darkgreyblue;
  font-size: var(--title-size-big);
  font-weight: bold;
}

p {
  color: $color-lightgreyblue;
  font-size: var(--title-size);
}

button {
  margin-top: 10px;
}
</style>
